<template>
  <div class="product">
    <div class="product-filter">
      <div class="product-filter-cond input">
        <el-input
          placeholder="找寻您心仪的设备机型"
          size="large"
          enter-button="搜索"></el-input>
        <span class="more" @click="isMore = !isMore" v-if="!isMore"> 更多筛选</span>
        <span class="more" @click="isMore = !isMore" v-else>收起筛选</span>
      </div>

      <div class="product-filter-cond labels" v-show="isMore">
        <div class="product-filter-cond-item">
          <div class="item-label">饮品分类</div>
          <div class="item-content">
            <span class="active">全部</span>
            <span>奶咖机型</span>
            <span>黑咖机型</span>
          </div>

        </div>

        <div class="product-filter-cond-item">
          <div class="item-label">小时出杯量</div>
          <div class="item-content">
            <span class="active">不限</span>
            <span>30杯以下</span>
            <span>30～50杯之间</span>
            <span>50～70杯之间</span>
            <span>70杯以上</span>
          </div>
        </div>

        <div class="product-filter-cond-item">
          <div class="item-label">可用粉料</div>
          <div class="item-content">
            <span class="active">全部</span>
            <span>无粉料盒</span>
            <span>单粉料盒</span>
            <span>两个粉料盒</span>
            <span>多个粉料盒</span>
          </div>
        </div>
      </div>
    </div>

    <div class="product-content">
      <div class="content-container">
        <div class="content-container-item" v-for="(product, key) in products" :key="key">
          <div class="item">
            <div class="item-img">
              <img :src="product.img"/>
            </div>
            <p class="item-title">{{product.name}}</p>
            <div class="item-info">
              <p class="item-info-remark" v-html="product.remark"></p>
              <p v-if="product.devices">已有设备:{{product.devices}}台</p>
            </div>
            <div class="item-opr">
              <el-button v-if="product.devices >0" type="primary" ghost size="small">查看设备</el-button>
              <el-button v-else type="primary" size="small">申请接入</el-button>
            </div>
          </div>
        </div>
        <div v-if="products.length ===0" class="content-container-empty">
          <empty>
            <div class="empty-information">
              <p>未能找寻到您心仪的机型</p>
              <p>如您需要接入, <el-button type="primary" size="small">联系我们</el-button> </p>
            </div>
          </empty>

        </div>
      </div>


      <el-pagination :default-current="1" :total="4" class="content-pagination" v-show="products.length > 0"/>
    </div>

  </div>

</template>

<script>

  import F10 from '@/assets/img/product/f10.png'

  export default {
    name: 'equipment-product',
    components: {},
    mounted: function () {
    },
    data: function () {
      return {
        isMore: false,
        f10: F10,
        products: [
          {name: '咖博士-F10', remark: '奶咖机型<br/>单小时出杯量：70<br/>适用酒店、会议招待等场景', img: F10, devices: 10},
          {name: '咖博士-F10', remark: '奶咖机型<br/>单小时出杯量：70<br/>适用酒店、会议招待等场景', img: F10, devices: 0},
          {name: '咖博士-F10', remark: '奶咖机型<br/>单小时出杯量：70<br/>适用酒店、会议招待等场景', img: F10, devices: 10},
          {name: '咖博士-F10', remark: '奶咖机型<br/>单小时出杯量：70<br/>适用酒店、会议招待等场景', img: F10, devices: 10},
        ]
      }
    },
    methods: {},
  }
</script>

<style lang="scss" scoped>
  .product {
    overflow-y: scroll;

    ::v-deep .el-pagination {
      button:disabled, .el-pager li{
        background: transparent;
      }
    }

    &-filter {
      padding: $container-padding;
      background-color: $color-white;

      .input {
        display: flex;
        justify-content: center;
        justify-items: center;
        align-items: center;


        .more {
          margin-left: $middle-space;
          color: $theme-color;
          cursor: pointer;
        }

        .more:hover {
          font-weight: 500;
        }
      }

      .labels {
        /*background-color: #fafafa;*/
        // padding: 0 $small-space;
      }

      &-cond {
        width: 64%;
        min-width: 320px;
        margin: auto;

        &-item {
          margin-top: $small-space;

          .item-label {
            font-weight: 500;
            margin-right: $small-space;
          }

          .item-content {
            margin-top: 4px;
            @include font-little();

            span {
              margin-right: $small-space;
              padding: 4px $small-space;
              cursor: pointer;
            }

            span:hover, .active {
              background-color: $theme-color;
              color: $color-white;
            }
          }
        }
      }


    }

    &-content {
      height: 78%;
      margin: $container-padding;

      .content-container {
        display: flex;
        flex-wrap: wrap;
        height: 100%;

        &-item {
          cursor: pointer;
          text-align: center;
          width: 24%;
          margin-bottom: $middle-space;

          .item {
            background-color: $color-white;
            height: 320px;
            padding: $large-space 0;

            &-img {
              img {
                width: 120px;
                height: 120px;
              }
            }

            &-title {
              margin-top: $small-space;
              margin-bottom: $small-space;
              @include font-medium();
              color: $color-black;
              font-weight: 500;
            }

            &-info{
              &-remark {
                @include font-little();
              }
              height: 80px;
              overflow:hidden;
            }

            &-opr{
              margin-top: $small-space;
            }

          }

          .item:hover {
            box-shadow: $box-shadow;
          }
        }

        &-item:not(:nth-child(4n)) {
          margin-right: calc(4% / 3);
        }

        &-empty{
         margin: auto;
          .empty-information{
            margin-top: $middle-space;
            text-align: center;
            p{
              margin-top: $small-space;
            }
          }
        }
      }

      .content-pagination {
        text-align: center;

      }


    }
  }
</style>
